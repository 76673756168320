'use client';

import React from 'react';
import classNames from 'classnames';
import { useAtomValue } from 'jotai';
import Image from 'next/image';

import Button, { ButtonVariants } from '../Button';
import Logo from '../Logo';

import deviceScreenSize, { ScreenSize } from '../../store/deviceScreenSize';
import { theme } from '../../store/theme';

import styles from './Error.module.scss';

const UnderMaintenance = () => {
  const deviceSize = useAtomValue(deviceScreenSize);
  const appTheme = useAtomValue(theme);
  return (
    <>
      <div
        aria-hidden='true'
        className={styles.background}
      >
        <span className={classNames(styles.ellipse, styles.ellipse__bottom_left)} />
        <span className={classNames(styles.ellipse, styles.ellipse__top_right)} />
        <Image
          src='/media/structure.svg'
          width={deviceSize < ScreenSize.DESKTOP_SMALL ? 648 : 1078}
          height={deviceSize < ScreenSize.DESKTOP_SMALL ? 320 : 533}
          className={styles.structure_top}
          alt=''
        />
        <Image
          src='/media/structure.svg'
          width={deviceSize < ScreenSize.DESKTOP_SMALL ? 648 : 1078}
          height={deviceSize < ScreenSize.DESKTOP_SMALL ? 320 : 533}
          className={styles.structure_bottom}
          alt=''
        />
      </div>
      <section className={styles.wrapper}>
        <div className={styles.animationGroup}>
          <Image
            width={deviceSize === ScreenSize.MOBILE ? 24 : 27}
            height={deviceSize === ScreenSize.MOBILE ? 24 : 27}
            src={`/media/polygons/polygon-gradient-${appTheme}.svg`}
            className={styles.animationGroup__smallGradient}
            alt=''
          />
          <Image
            width={deviceSize === ScreenSize.MOBILE ? 71 : 80}
            height={deviceSize === ScreenSize.MOBILE ? 71 : 80}
            src={`/media/polygons/polygon-gradient-${appTheme}.svg`}
            className={styles.animationGroup__largeGradient}
            alt=''
          />
          <Image
            width={deviceSize === ScreenSize.MOBILE ? 119 : 134}
            height={deviceSize === ScreenSize.MOBILE ? 119 : 134}
            src={`/media/polygons/polygon-border-${appTheme}.svg`}
            className={styles.animationGroup__noise}
            alt=''
          />
        </div>

        <div className={classNames(styles.content, styles.content_marginTop)}>
          {/* Using a normal anchor tag until Link works on the error page */}
          <a
            href='/'
            aria-label='Get back to main page'
            className={styles.logo}
          >
            <Logo
              width={156}
              height={52}
            />
          </a>
          <h2 className={styles.title}>
            We&apos;re
            {' '}
            <span className={styles.title_accent}>Tinkering</span>
            {' '}
            Behind the Scenes. Stay Tuned!
          </h2>
          <p className={styles.text}>
            Our tech crew is on it, working to resolve the issue and enhance your experience. We apologize for any inconvenience and thank
            you for your patience.
          </p>
          <a href='/'>
            <Button
              className={styles.btn_short}
              variant={ButtonVariants.OUTLINED}
              icon={(
                <svg className={styles.btn__icon}>
                  <use
                    xlinkHref='/media/icon-nest.svg#iconNest'
                    href='/media/icon-nest.svg#iconNest'
                  />
                </svg>
              )}
              iconSize={{
                width: 24,
                height: 24,
              }}
            >
              Back to home
            </Button>
          </a>
        </div>
      </section>
    </>
  );
};

export default UnderMaintenance;
